




















import Vue, { PropOptions } from 'vue';
import type { Button } from '@/components/Buttons/Button.vue';

export type Buttons = Blok & {
  id: string;
  elements: Button[];
  spacing: string;
  align: 'auto' | 'left' | 'center' | 'right';
};

export default Vue.extend({
  props: {
    body: {
      type: Object,
      required: true,
    } as PropOptions<Buttons>,
  },
});
