//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentImage: { filename: '', alt: '' },
    };
  },
  mounted() {
    this.currentImage = this.blok.image;
  },
  methods: {
    setCurrentImage(image) {
      if (image?.filename) {
        this.currentImage = image;
      }
    },
  },
};
