import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { useStoryblokApi } from '@storyblok/nuxt-2';

export const state = () => ({
  cacheVersion: '',
  themelight: false,
  colorMode: '',
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  isLight: (state) => state.colorMode === 'light',
};

export const mutations: MutationTree<RootState> = {
  setCacheVersion(state, version) {
    state.cacheVersion = version;
  },
  setThemeLight(state, boolean) {
    state.themelight = boolean;
  },
  setColorMode(state, value) {
    state.colorMode = value;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  changeColorMode({ commit }, value) {
    if (value === undefined) return;
    if (process.browser) {
      const bodyClassName = document.documentElement.className;
      bodyClassName.replace(/\w*-mode/g, '');
      document.documentElement.className = value ? `${value}-mode` : '';

      commit('setColorMode', value, { root: true });
    }
  },
  loadCacheVersion({ commit }) {
    const storyblokApi = useStoryblokApi();
    return storyblokApi.get(`cdn/spaces/me`).then((res: any) => {
      commit('setCacheVersion', res.data.space.version);
    });
  },
};
