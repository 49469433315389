//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    error: { type: Object, required: true },
  },

  data() {
    return { header: null, blok: null, footer: null };
  },

  async fetch() {
    if (this.error.statusCode === 404) {
      const res = await this.$storyapi.get('cdn/stories/notfound', {
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        resolve_relations: 'global_reference.reference',
      });

      const { global, body } = res.data.story.content;

      this.header = global[0].reference.content.global[0];
      this.blok = body[0];
      this.footer = global[1].reference.content.global[0];
    }
  },
};
