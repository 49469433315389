//
//
//
//
//
//
//
//

export default {
  props: ["blok"],
  computed: {
    style() {
      return {
        "z-index": "100",
        "--font-size": this.blok.fontSize + "px",
        "--font-size-xl": this.blok.fontSize - 20 + "px",
        "--font-size-xlg": this.blok.fontSize - 30 + "px",
        "--font-size-lg": this.blok.fontSize - 40 + "px",
        "--font-size-sm": this.blok.fontSize - 50 + "px",
        "--line-height": this.blok.lineHeight + "px",
      };
      /*return {
        fontSize: `${this.blok.fontSize}px`,
        lineHeight: `${this.blok.lineHeight}px`
      };*/
    },
    imageContainerStyle() {
      return {
        backgroundImage: "url(/chrome-extension-1.svg)",
        backgroundRepeat: "no-repeat",
        height: "490px",
        position: "absolute",
        width: "100%",
        right: "0px",
        backgroundPositionX: "100%",
        top: "240px",
        "background-size": "490px",
      };
    },
  },
};
