//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Description from "./Description";

export default {
  props: ["blok"],
  components: {
    Description,
  },
};
