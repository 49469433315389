








import Vue from 'vue';
import CookieConsent from '@/components/CookieConsent.vue';

export default Vue.extend({
  components: { CookieConsent },
  data() {
    return {
      showCookieConsent: true,
    };
  },
  mounted() {
    const ignoreCookieConsent = [
      '/lp',
      'get-code-for-a-single-screen',
      'get-full-website-and-web-app',
      'add-code-to-your-existing-project'
    ];
    // if path includes any of the ignore paths, don't show cookie consent
    this.showCookieConsent = !ignoreCookieConsent.some((path) =>
      this.$route.path.includes(path)
    );
    if (this.$exp.name === 'color-mode') {
      this.$store.dispatch(
        'changeColorMode',
        this.$exp.$activeVariants[0].name
      );
    }
  },
});
