































































































import Vue, { PropOptions } from 'vue';

interface VideoSource {
  id: string;
  filename: string;
}

interface SmallVideoPlayer extends Blok {
  source: VideoSource[];
  thumbnail: BlokFieldAsset;
  title: string;
  ctaText: string;
}

export default Vue.extend({
  props: {
    body: {
      type: Object,
      required: true,
    } as PropOptions<SmallVideoPlayer>,
  },
  data() {
    return {
      isPaused: true,
      isOpen: false,
      remainingTime: '',
    };
  },
  watch: {
    isOpen(newState): void {
      document.body.style.overflow = newState ? 'hidden' : '';
      if (newState) {
        this.restartVideo();
      }
    },
  },
  methods: {
    onPlay() {
      this.isPaused = false;
    },
    onPause() {
      this.isPaused = true;
    },
    togglePlayPause() {
      const video = this.$refs.video as HTMLVideoElement;
      if (video) {
        if (this.isPaused) {
          video.play();
        } else {
          video.pause();
        }
      }
    },
    updateRemainingTime() {
      const video = this.$refs.video as HTMLVideoElement | undefined;
      if (video) {
        const timeLeft = video.duration - video.currentTime;
        this.remainingTime = this.formatTime(timeLeft);
      }
    },
    formatTime(seconds: number) {
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.floor(seconds % 60);
      return (
        [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
          .filter((a) => a)
          .join(':') + 's'
      );
    },
    restartVideo() {
      const video = this.$refs.video as HTMLVideoElement;
      if (video) {
        video.currentTime = 0;
        video.play();
      }
    },
  },
});
