var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionContainer',{attrs:{"paddingTop":_vm.blok.paddingTop,"paddingBottom":_vm.blok.paddingBottom}},[(_vm.blok.backgroundImageDesktop && _vm.blok.backgroundImageDesktop.filename)?_c('img',{staticClass:"background-image-desktop",class:{ 'full-width': _vm.blok.fullWidthBackground },style:({ objectPosition: _vm.blok.backgroundImagePosition || 'center' }),attrs:{"src":_vm.blok.backgroundImageDesktop.filename,"alt":_vm.blok.backgroundImageDesktop.alt || _vm.blok.backgroundImageDesktop.filename}}):_vm._e(),_vm._v(" "),(_vm.blok.backgroundImageMobile && _vm.blok.backgroundImageMobile.filename)?_c('img',{staticClass:"background-image-mobile",class:{ 'full-width': _vm.blok.fullWidthBackground },style:({ objectPosition: _vm.blok.backgroundImagePosition || 'center' }),attrs:{"src":_vm.blok.backgroundImageMobile.filename,"alt":_vm.blok.backgroundImageMobile.alt || _vm.blok.backgroundImageMobile.filename}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"grid",class:{
      'align-left': _vm.blok.mediaPosition === 'right',
      'align-right': _vm.blok.mediaPosition === 'left',
    },attrs:{"id":_vm.blok.id}},[_c('div',{staticClass:"content",class:{
        center:
          (_vm.blok.centerVertically === 'content' ||
            _vm.blok.centerVertically === 'content-and-media') &&
          _vm.blok.mediaPosition !== 'center',
      }},[(_vm.blok.showAdditionalInfo)?_c('Heading',{staticClass:"additionalText",class:_vm.blok.additionalInfoFontSize,style:(_vm.additionalInfoStyle),attrs:{"tag":_vm.blok.additionalInfoTag}},[_vm._v("\n        "+_vm._s(_vm.blok.additionalInfoText)+"\n      ")]):_vm._e(),_vm._v(" "),_c('Heading',{staticClass:"title",class:_vm.blok.titleFontSize,attrs:{"tag":_vm.blok.titleTag}},[_vm._v("\n        "+_vm._s(_vm.blok.title)+"\n      ")]),_vm._v(" "),(!!_vm.blok.text)?_c('RichTextV2',{attrs:{"text":_vm.blok.text}}):_vm._e()],1),_vm._v(" "),(_vm.hasMedia)?_c('div',{staticClass:"media",class:{
        center:
          _vm.blok.centerVertically === 'media' ||
          _vm.blok.centerVertically === 'content-and-media',
      }},[(_vm.hasImage)?_c('StoryblokComponent',{staticClass:"image",class:{ 'full-width': _vm.blok.paddingBottom === 'none' },attrs:{"blok":_vm.blok.image[0]}}):(_vm.hasVideo)?_c('StoryblokComponent',{attrs:{"blok":_vm.blok.video[0]}}):(_vm.hasYoutubeVideo)?_c('StoryblokComponent',{attrs:{"blok":_vm.blok.youtubeVideo[0]}}):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }