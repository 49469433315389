import Vue from "vue";
import kebabCase from "lodash/kebabCase";

Vue.filter("resize", (image, size) => {
  if (typeof image !== "undefined") {
    return (
      "//img2.storyblok.com/" + size + image.replace("//a.storyblok.com", "")
    );
  }
  return null;
});

Vue.filter("dashify", function (value) {
  if (typeof value === "undefined") {
    return "blok-not-found";
  }
  return "blok-" + kebabCase(value);
});
