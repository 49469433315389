//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: { required: true, type: String },
    description: { required: true, type: String },
    icons: { type: Array },
  },
  components: {},
};
