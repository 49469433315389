


















































































import Vue, { PropOptions } from 'vue';
import type { HeadingTag } from '@/components/Heading.vue';
import type { PaddingOptions } from '@/components/SectionContainer.vue';

export type ContentWithMedia = Blok & {
  id: string;
  showAdditionalInfo?: true;
  additionalInfoText?: string;
  additionalInfoFontSize?: string;
  additionalInfoColor?: string;
  additionalInfoTag: HeadingTag;
  title?: string;
  titleFontSize?: string;
  titleTag: HeadingTag;
  text?: string;
  image: Blok[];
  video: Blok[];
  youtubeVideo: Blok[];
  mediaPosition?: 'left' | 'center' | 'right';
  backgroundImageDesktop?: BlokFieldAsset;
  backgroundImageMobile?: BlokFieldAsset;
  backgroundImagePosition?: string;
  fullWidthBackground?: boolean;
  paddingTop?: PaddingOptions;
  paddingBottom?: PaddingOptions;
  centerVertically?: 'nothing' | 'content' | 'media' | 'content-and-media';
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<ContentWithMedia>,
  },
  computed: {
    hasImage(): boolean {
      return this.blok.image && this.blok.image.length > 0;
    },
    hasVideo(): boolean {
      return this.blok.video && this.blok.video.length > 0;
    },
    hasYoutubeVideo(): boolean {
      return this.blok.youtubeVideo && this.blok.youtubeVideo.length > 0;
    },
    hasMedia(): boolean {
      return this.hasImage || this.hasVideo || this.hasYoutubeVideo;
    },
    additionalInfoStyle(): {
      color?: string;
    } {
      return {
        color: this.blok.additionalInfoColor
          ? `var(--${this.blok.additionalInfoColor})`
          : undefined,
      };
    },
  },
});
