








export type PaddingOptions = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export default {
  props: {
    paddingTop: {
      type: String as () => PaddingOptions,
      default: undefined,
    },
    paddingBottom: {
      type: String as () => PaddingOptions,
      default: undefined,
    },
  },
  computed: {
    paddingStyle({
      paddingTop,
      paddingBottom,
    }: {
      paddingTop?: PaddingOptions;
      paddingBottom?: PaddingOptions;
    }) {
      const paddingOptions = {
        none: '0',
        xs: 'var(--spacing-xs)',
        sm: 'var(--spacing-sm)',
        md: 'var(--spacing-md)',
        lg: 'var(--spacing-lg)',
        xl: 'var(--spacing-xl)',
        xxl: 'var(--spacing-xxl)',
      };

      return {
        '--padding-top': paddingOptions[paddingTop || 'xxl'],
        '--padding-bottom': paddingOptions[paddingBottom || 'xxl'],
      };
    },
  },
};
