//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueHorizontal from "vue-horizontal";

export default {
  name: "horizontal-scroller-items",
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueHorizontal,
  },
  data() {
    return {
      videoSrc: "http://www.youtube.com/embed/t8uKqpVt0LE",
      items: this.blok.dataList,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.tutorials.refresh();
    }, 300);
  },
  methods: {
    openModal(src) {
      let list = "?playlist=";
      let selIndex = null;
      this.items.forEach((el, index) => {
        if (el.videoLink == src) {
          selIndex = index;
          const videoId = el.videoLink.split("embed/")[1];
          list = list + videoId + ",";
        } else if (selIndex || selIndex === 0) {
          const videoId = el.videoLink.split("embed/")[1];
          list = list + videoId + ",";
        }
      });
      let updatedSrc = "";
      if (selIndex || selIndex === 0) {
        updatedSrc = src + list;
      } else {
        updatedSrc = src;
      }
      updatedSrc = updatedSrc.slice(0, -1);
      updatedSrc = updatedSrc + "&autoplay=1";
      this.$parent.changeVideoSrc(updatedSrc);
      // console.log(this.$parent.videoLink);
      // http://www.youtube.com/embed/t8uKqpVt0LE
    },
  },
};
