











































































































import Vue, { PropOptions } from 'vue';

export type UTMTagBlok = Blok & {
  name: string;
  value: string;
};

export type Link = Blok & {
  url: BlokFieldLink;
  id: string;
  label: string;
  description: string;
  icon: BlokFieldAsset;
  target: '_self' | '_blank';
  style: 'link' | 'button' | 'button-large';
  snippet: string;
  links: BlokFieldLink[];
  utm_tags: UTMTagBlok[];
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<Link>,
    mobileMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    url() {
      const { cached_url, url } = this.blok.url;
      try {
        const resultUrl = new URL(cached_url || url);
        const currentSearchParams = new URLSearchParams(window.location.search);
        this.blok.utm_tags.map((utmTag) => {
          return resultUrl.searchParams.set(utmTag.name, utmTag.value);
        });
        for (const [key, value] of currentSearchParams.entries()) {
          resultUrl.searchParams.set(key, value);
        }
        return resultUrl.toString();
      } catch (e) {
        return cached_url || url;
      }
    },
    isInternalLink() {
      return (
        this.blok.url?.linktype === 'story' &&
        this.blok.url?.cached_url &&
        this.blok?.links?.length !== 0
      );
    },
    isExternalLink() {
      return (
        this.blok.url?.linktype === 'url' ||
        this.blok.url?.url?.startsWith('https')
      );
    },
    isMdAndDown() {
      return matchMedia('(max-width: 768px)').matches;
    },
    isLgAndUp() {
      return matchMedia('(min-width: 1024px)').matches;
    },
  },
  methods: {
    onClick() {
      if (this.isMdAndDown) {
        this.show = !this.show;
      }
      if (this.blok.label) {
        // if there's no label, it means it's a collapse of multi-link.
        this.$tracking.trackEvent('website.button.clicked', {
          label: this.blok.label,
          currentURL: this.$route.fullPath,
        });
      }
      if (this.blok.snippet) {
        // eslint-disable-next-line no-eval
        eval(this.blok.snippet);
      }
    },
    onMouseOver() {
      if (this.isLgAndUp) {
        this.show = true;
      }
    },
    onMouseLeave() {
      if (this.isLgAndUp) {
        this.show = false;
      }
    },
    getLink(col: { label_link: BlokFieldLink }) {
      return col?.label_link?.cached_url || col?.label_link?.url;
    },
  },
});
