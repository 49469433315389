//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentImage: null,
    };
  },
  mounted() {
    this.currentImage = this.blok.default;
  },
  methods: {
    setCurrentImage(image) {
      this.currentImage = image;
    },
  },
};
