






























import Vue, { PropOptions } from 'vue';

export type Button = Blok & {
  id: string;
  label: string;
  url: BlokFieldLink;
  target: '_self' | '_blank';
  variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'white'
    | 'white-secondary'
    | 'link';
  size: 'small' | 'medium' | 'big';
  showIcon: boolean;
  iconAnimation?: boolean;
  icon?: 'arrow' | 'play';
  events?: string;
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<Button>,
  },
  computed: {
    url() {
      const { cached_url, url } = this.blok.url;
      try {
        const resultUrl = new URL(cached_url || url);
        const currentSearchParams = new URLSearchParams(window.location.search);
        for (const [key, value] of currentSearchParams.entries()) {
          resultUrl.searchParams.set(key, value);
        }
        return resultUrl.toString();
      } catch (e) {
        return cached_url || url;
      }
    },
    isInternalLink() {
      return this.blok.url?.linktype === 'story' && this.blok.url?.cached_url;
    },
    buttonClasses() {
      return {
        [this.blok.variant]: this.blok.variant,
        [this.blok.size]: this.blok.size,
        'animated-icon': this.blok.iconAnimation && this.blok.icon === 'arrow',
        'text-mulish-sm': this.blok.size === 'small',
        'text-mulish-md': this.blok.size === 'medium',
        'text-mulish-lg': this.blok.size === 'big',
      };
    },
  },
  methods: {
    onClick() {
      this.$tracking.trackEvent('website.button.clicked', {
        label: this.blok.label,
        currentURL: this.$route.fullPath,
      });

      if (this.blok.events) {
        this.blok.events
          .split(',')
          .map((event) => event.trim())
          .filter((event) => event.length > 0)
          .forEach((event) =>
            this.$gtm.push({
              event,
              event_category: 'Button',
              event_action: `"${this.blok.label}" Button Clicked`,
            })
          );
      }
    },
    getLink(col: { label_link: BlokFieldLink }) {
      return col?.label_link?.cached_url || col?.label_link?.url;
    },
  },
});
