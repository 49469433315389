//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueIfBot from 'vue-if-bot/dist/vue-if-bot.es';
import { createCookie, readCookie } from '@/utils/cookie';

export default {
  components: {
    VueIfBot,
  },
  data() {
    return {
      show: undefined,
      cookieName: 'cookieconsent_status',
      cookieExpiryDays: 365,
    };
  },
  computed: {
    cookie() {
      return !readCookie(this.cookieName);
    },
  },
  beforeMount() {
    this.show = this.cookie;
  },
  methods: {
    dismiss() {
      this.show = false;
      createCookie(this.cookieName, 1, this.cookieExpiryDays);
    },
  },
};
