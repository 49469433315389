//
//
//
//
//
//

export default {
  props: ["blok"],
  computed: {
    style() {
      return {
        fontSize: `${this.blok.fontSize}px`,
        lineHeight: `${this.blok.lineHeight}px`,
        opacity: `${this.blok.textOpacity}`,
      };
    }
  }
};
