











import Vue, { PropOptions } from 'vue';

export type HomePage = Blok & {
  sections: Blok[];
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<HomePage>,
  },
  // mounted() {
  //   this.$intercom('boot');
  // },
  // beforeDestroy() {
  //   this.$intercom('shutdown');
  // },
});
