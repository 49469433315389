














import { defineComponent } from '@nuxtjs/composition-api';

interface Props extends Blok {
  dataUrl: any;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmTerm?: string;
}

interface EventDetails {
  email?: string;
  name?: string;
  phone_number?: string;
}

export default defineComponent({
  props: {
    blok: {
      type: Object as () => Props,
      required: true,
    },
  },
  data() {
    return {
      calendlyUr: '',
    };
  },
  mounted() {
    const url = new URL(this.blok.dataUrl.url);
    url.searchParams.append('hide_gdpr_banner', '1');
    let prefillDetails: EventDetails = this.$route.query;
    if (!prefillDetails.email && localStorage.getItem('calendlyEvent')) {
      prefillDetails = JSON.parse(
        localStorage.getItem('calendlyEvent') || '{}'
      );
      localStorage.removeItem('calendlyEvent');
    }
    Object.entries(prefillDetails).forEach(([key, value]):void => {
      url.searchParams.append(key, value);
    });
    this.calendlyUr = `${url.href}`;
    const calendlyFormRef: any = this.$refs.calendlyForm;
    if ( calendlyFormRef?.childElementCount > 0) {
      calendlyFormRef.querySelector('iframe').src = url.href
    } else {
      calendlyFormRef.dataset.url = url.href;
    }
  },
});
