






import Vue, { PropOptions } from 'vue';

type RichTextProps = Blok & {
  text: string;
  marginTop: number;
  desktopSizeHeader: number;
  desktopSizeContent: number;
};

export default Vue.extend({
  props: {
    id: { type: String, default: '' },
    blok: {
      type: Object,
      required: true,
    } as PropOptions<RichTextProps>,
  },
  computed: {
    cssVars(): CSSVars {
      return {
        '--rich-text-margin-top': this.blok?.marginTop
          ? `${this.blok.marginTop}rem`
          : '',
        '--rich-text-size-header': this.blok?.desktopSizeHeader
          ? `${this.blok.desktopSizeHeader}ch`
          : '',
        '--rich-text-size-content': this.blok?.desktopSizeContent
          ? `${this.blok.desktopSizeContent}ch`
          : '',
      };
    },
  },
});
