//
//
//
//
//
//

export default {
  props: ["blok", "active"],
  computed: {
    richtext() {
      return this.blok.content
        ? this.$storyapi.richTextResolver.render(this.blok.content)
        : "";
    }
  }
};
