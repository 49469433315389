








































import Vue, { PropOptions } from 'vue';

export type DropdownItem = Blok & {
  label: string;
  icon: BlokFieldAsset;
  url: BlokFieldLink;
  target: '_self' | '_blank';
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<DropdownItem>,
  },
  computed: {
    url() {
      const { cached_url, url } = this.blok.url;
      try {
        const resultUrl = new URL(cached_url || url);
        const currentSearchParams = new URLSearchParams(window.location.search);
        for (const [key, value] of currentSearchParams.entries()) {
          resultUrl.searchParams.set(key, value);
        }
        return resultUrl.toString();
      } catch (e) {
        return cached_url || url;
      }
    },
    isInternalLink() {
      return this.blok.url?.linktype === 'story' && this.blok.url?.cached_url;
    },
  },
  methods: {
    onClick() {
      this.$tracking.trackEvent('website.button.clicked', {
        label: this.blok.label,
        currentURL: this.$route.fullPath,
      });
    },
    getLink(col: { label_link: BlokFieldLink }) {
      return col?.label_link?.cached_url || col?.label_link?.url;
    },
  },
});
