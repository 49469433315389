








































































import Vue, { PropOptions } from 'vue';
import { mapGetters } from 'vuex';
import type { FooterSocialIcon } from '~/components/storyblok/FooterSocialIcon.vue';
import type { FooterColumn } from '~/components/storyblok/FooterColumn.vue';

export type Footer = Blok & {
  logo: BlokFieldAsset;
  shortDescription: string;
  socialIcons: FooterSocialIcon[];
  columns: FooterColumn[];
  privacyPolicyUrl: BlokFieldLink;
  termsOfUseUrl: BlokFieldLink;
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<Footer>,
  },
  computed: {
    ...mapGetters(['isLight']),
  },
});
