//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HorizontalScrollerItems from "~/components/HorizontalScroller/HorizontalScrollerItems.vue";
export default {
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  components: {
    HorizontalScrollerItems
  },
  data() {
    return {
      videoLink: "",
    };
  },
  watch: {

    videoLink(newValue, preValue){
        // console.log("Watcher: "+this.videoLink);
    }

  },
  methods: {
    changeVideoSrc: function(src) {
      this.videoLink = src
      this.$modal.show('videoModal')
    }
  },
  computed: {
    style() {
      return {
        borderRadius: '20px'
      };
    }
  }
};
