//
//
//
//
//
//

export default {
  props: ["blok"],
  data() {
    return {
      selectedImage: null,
    };
  },

  watch: {
    blok: {
      immediate: true,
      handler(val) {
        if (val.image) {
          this.selectedImage = val.image.filename;
        }
      },
    },
  }
};
