//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "hero-bottom",
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  computed: {
    style() {
      return {
        borderRadius: '20px'
      };
    }
  }
};
