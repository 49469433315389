







































import Vue, { PropOptions } from 'vue';

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: true,
    } as PropOptions<'arrow' | 'play' | undefined>,
  },
});
