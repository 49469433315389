










import Vue, { PropType } from 'vue';

export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined;

export default Vue.extend({
  props: {
    tag: {
      type: String as PropType<HeadingTag>,
      required: false,
    },
  },
});
