import Vue from 'vue';
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer';
import RichText from '@/components/storyblok/RichText.vue';
import Buttons from '@/components/storyblok/Buttons.vue';
import Bulletpoints from '@/components/storyblok/Bulletpoints.vue';
import SmallVideoPlayer from '@/components/storyblok/SmallVideoPlayer.vue';
import RichTextSubscript from '@/components/RichText/RichTextSubscript.vue';
import RichTextSuperscript from '@/components/RichText/RichTextSuperscript.vue';
import RichTextTextStyle from '@/components/RichText/RichTextTextStyle.vue';

Vue.use(VueRichTextRenderer, {
  resolvers: {
    components: {
      RichText,
      Buttons,
      Bulletpoints,
      SmallVideoPlayer,
    },
    marks: {
      subscript: RichTextSubscript,
      superscript: RichTextSuperscript,
      textStyle: RichTextTextStyle,
    },
  },
});
