//
//
//
//

export default {
  computed: {
    computedStyle() {
      const node = this.$vnode;
      return node?.data?.props?.attrs;
    },
  },
};
