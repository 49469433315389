















import Vue, { PropOptions } from 'vue';
import type { Bulletpoint } from '@/components/storyblok/Bulletpoint.vue';

export type Bulletpoints = Blok & {
  spacing: string;
  elements: Bulletpoint[];
};

export default Vue.extend({
  props: {
    body: {
      type: Object,
      required: true,
    } as PropOptions<Bulletpoints>,
  },
});
