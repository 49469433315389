










import { defineComponent } from '@nuxtjs/composition-api';

interface Props extends Blok {
  image: BlokFieldAsset;
  width: string;
  height: string;
  altText: string;
  aspectRatio: '16/9' | '1/1';
}

export default defineComponent({
  props: {
    blok: { type: Object as () => Props, required: true },
  },
  computed: {
    cssVars(): CSSVars {
      return {
        '--width': this.blok?.width,
        '--height': this.blok?.height,
        '--aspect-ratio': this.blok?.aspectRatio,
      };
    },
  },
});
