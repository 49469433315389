//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'nav-bar',
  props: { 
    blok: {
      type: Object,
      required: true
    }
  }
}
